// Colors
$black: #4e4e4e;
$light-blue: #609dc6;
$blue: #406ab3;
$green: #71bf44;
$purple: #7a60c4;
$red: #dc3546;
$yellow: #fec107;
$orange: #fd7e14;
$gray: #a8a8a8;

$soft-shadow: #83838315;
$background-white: #f9f9f9;

// Values
$border-radius-card: 0.35rem;
