$button-colors: (
    "primary": $blue,
    "secondary": $green,
    "purple": $purple,
    "danger": $red,
    "warning": $yellow,
    "gray": $gray
);

// Create button class for each color in $button-colors map
// Class names generated as .btn-${color key name}
@each $key, $val in $button-colors {
    .btn-#{$key} {
        background-color: $val;
        border-color: $val;
        color: white;
        @extend .text-emphasis;

        &:hover {
            $hover-color: darken(
                $color: $val,
                $amount: 10
            );

            background-color: $hover-color;
            border-color: $hover-color;
            color: white;
        }
    }

    .btn-#{$key}-outline {
        background-color: transparent;
        color: $val;
        border-width: 2px;
    }
}
