.k-pager-numbers .k-link,
.k-pager-numbers .k-link:link,
.k-icon {
    color: $black;
}

.k-widget,
.k-grid,
.k-pager-info,
.k-button {
    @extend #app;
}

.k-pager-numbers .k-link.k-state-selected {
    color: white;
    background-color: $blue;
}

.k-progressbar {
    display: none;
}

.mock-invalid-feedback {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.875em;
    color: $danger;
}

.upload-is-invalid {
    border-color: $danger;
}

.grid-is-invalid {
    .k-grid {
        @extend .upload-is-invalid;
    }
}

.k-dialog-wrapper {
    z-index: 10;
}
