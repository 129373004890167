.text-green {
    color: $green;
}

.text-purple {
    color: $purple;
}

.text-white {
    color: white;
}

.font-weight-bold {
    font-weight: bold;
}

.text-emphasis {
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.12em;
}
